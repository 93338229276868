@use "../../global" as *;

#distributors-page {
    .distributors-row {
        overflow: clip;
        @include flexDiv();

        .distributor-left-nav.nav-link.active {
            background-color: $actionColor;
            color: $primaryColor;
        }

        .distributor-right-nav {
            .title {
                color: $primaryColor;
                // padding: 0 2rem;
            }
        }
        @media only screen and (max-width: 768px) {
            .nav-pills{
                margin-block-end: 1rem;
            }

            .distributor-right-nav {
                .title {
                    padding: 0 1rem;
                }
            }
        }
    }

}