#about-page .about-card-component {
    border: 1px solid rgba(var(--primary-color), 1);
    border-radius: 1rem;
    background-color: #ffffff;
    text-align: start;
    margin: 3rem 0;
}

#about-page .about-card-component:nth-child(2n) {
    background-color: #f1f2f3;
}

#about-page .about-card-component:nth-child(2n) .about-card-description {
    order: -2;
}

#about-page .about-card-component p {
    padding: 0 !important;
}

/* Media Queries */
@media only screen and (max-width: 576px) {
    #about-page .about-card-component {
        text-align: center;
    }

    #about-page .about-card-component:nth-child(2n) .about-card-description {
        order: 0;
    }
}
