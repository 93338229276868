/* Buttons */
.btn-base {
    color: #ffffff;
    background: rgba(var(--primary-color), 1);
}

.btn-base-light {
    color: rgba(var(--primary-color), 1);
    background: #e5f1fb;
}

.btn {
    --bs-btn-font-weight: 600;
    --bs-btn-padding-x: 1rem;
    --bs-btn-padding-y: 0.8rem;
    position: relative;
    overflow: hidden;
    border: 0 !important;

    transition-property: color, background-color;
    transition-duration: var(--transition-duration);
}

.btn.disabled {
    background-color: rgba(var(--primary-color), 1) !important;
}

.btn:hover {
    background-color: rgba(var(--action-color), 1);
    color: rgba(var(--primary-color), 1);
}
