/* Map */
#contact-page #map iframe {
    height: 50vh !important;
    width: 100% !important;
    border: 2px solid rgba(var(--primary-color), 1) !important;
    border-radius: 0.5rem !important;
}

#contact-page #contact-info {
    color: rgba(var(--primary-color), 1);
}

@media only screen and (max-width: 768px) {
    #contact-page #contact-info img {
        margin: 0 auto 1rem !important;
    }
}

#contact-page .contact-icon {
    min-width: 2rem !important;
    height: 1.6rem !important;
    margin-right: 0.5rem !important;
}

#contact-page .contact {
    text-decoration: none;
    color: rgba(var(--primary-color), 1);

    transition: color var(--transition-duration);
}

#contact-page .contact:hover {
    color: rgba(var(--action-color), 1);
}


#contact-page .branch-row {
    width: 100%;
    color: #000 !important;
    margin-block: 1.5rem;
}
#contact-page .branch-row .branch-title {
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    margin-block-end: 0.4rem;
}
#contact-page .branch-row .branch-title-icon {
    margin-inline-end: 0.5rem;
    color: #445058 !important;
}
#contact-page .branch-row label {
    color: #445058 !important;
}
#contact-page .branch-row .contact {
    direction: ltr;
    text-align: match-parent;
    padding: 0 !important;
    margin: 0 !important;
    font-family: inherit !important;
}
#contact-page .branch-row .contact a {
    text-decoration: none;
    color: rgba(var(--primary-color), 1);
}
#contact-page .branch-row .contact a:hover {
    color: rgba(var(--action-color), 1);
}
