#not-found-page {
    background-color: rgba(var(--primary-color), 1);
    color: rgba(var(--action-color), 1)
}

#not-found-page .btn {
    color: rgba(var(--primary-color), 1);
    background: rgba(var(--action-color), 0.85);
}

#not-found-page .btn:hover {
    cursor: pointer;
    color: rgba(var(--primary-color), 1);
    background-color: rgba(var(--action-color), 1);
}
