/* Slider Active Bullet */
#home-page .swiper-pagination-bullet-active {
    background: rgb(var(--action-color));
}

/* Slider Slide */
#home-page .swiper-slide {
    background-color: rgba(var(--primary-color), 1);
    background: linear-gradient(to bottom, rgba(var(--primary-color), 0.3), rgba(var(--primary-color), 0.3)), var(--image-url), var(--loader-url);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover, cover, contain;
}

/* Slider Text Content */
#home-page .slider-content {
    color: #ffffff;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    max-width: 60rem;
}

#home-page .slider-content .title {
    max-width: 75%;
    font-size: 4rem;
    margin-bottom: 2rem;
}

#home-page .slider-content p {
    margin: 0;
    max-width: 50%;
    line-height: 2;
}

@media only screen and (max-width: 992px) {
    #home-page .slider-content .title {
        max-width: 50%;
        font-size: 2rem;
        margin-bottom: 1.6rem;
    }
}

@media only screen and (max-width: 768px) {
    #home-page .slider-content {
        max-width: 100%;
        text-align: center;
    }

    #home-page .slider-content .title {
        max-width: 100%;
        font-size: 2rem;
        margin-bottom: 1.6rem;
    }

    #home-page .slider-content p {
        max-width: 100%;
        line-height: 1.6;
    }
}

/* Content Animation */
#home-page .swiper-slide .slider-content {
    animation-name: none;
    animation-play-state: paused;
}

#home-page .swiper-slide.swiper-slide-active .slider-content {
    animation-play-state: running;
    animation-name: fadeInUp;
}

/* ********************************************************* */
/* ********************************************************* */
/* ********************************************************* */
/* Services Section */
#home-page #services-section .section-head .title {
    margin-bottom: 2rem;
}

#home-page #services-section .section-head p {
    line-height: 2;
    padding: 0;
}

@media only screen and (max-width: 768px) {
    #home-page #services-section .section-head {
        text-align: center;
    }

    #home-page #services-section .section-head .title {
        margin-bottom: 1.6rem;
    }

    #home-page #services-section .section-head p {
        line-height: 1.6;
    }
}

/* ********************************************************* */
/* ********************************************************* */
/* ********************************************************* */
/* Separator Section */
#home-page .separator-section {
    background-color: #163f70;
    color: #ffffff;
    padding: 8rem 1rem;
}

#home-page #plain-section .title {
    font-size: 3rem;
    margin-bottom: 3rem;
}

#home-page #plain-section p {
    margin: 0;
    padding: 0;
    line-height: 2;
}

@media only screen and (max-width: 1400px) {
    #home-page #plain-section .title {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }

    #home-page #plain-section p {
        line-height: 1.6;
    }
}

@media only screen and (max-width: 1200px) {
    #home-page #plain-section .title {
        font-size: 2.2rem;
        margin-bottom: 2rem;
    }

    #home-page #plain-section p {
        line-height: 1.6;
    }
}

@media only screen and (max-width: 768px) {
    #home-page #plain-section .title {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    #home-page #plain-section p {
        line-height: 1.6;
    }
}

/* ********************************************************* */
/* ********************************************************* */
/* ********************************************************* */
/* Content With Side Image */
.section-two{
    max-width: 90rem;
}
#home-page #image-section {
    text-align: start;
}

#home-page #image-section .title {
    margin-bottom: 2rem;
}

#home-page #image-section p {
    padding-right: 4rem;
}

#home-page #image-section p>*{
    padding: 0 !important;
}

@media only screen and (max-width: 768px) {
    #home-page #image-section {
        text-align: center;
    }

    #home-page #image-section .title {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }

    #home-page #image-section p {
        padding-right: unset;
    }
}

@media only screen and (max-width: 1440px) {
    #home-page #image-section .title , p{
        padding:0 2rem;
    }
    #home-page #image-section p {
        padding:0 2rem;
    }
}

/* ********************************************************* */
/* ********************************************************* */
/* ********************************************************* */
