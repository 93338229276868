@use "../../global" as *;

#general-page {

    .general-card-component {
        border: 1px solid $primaryColor;
        background-color: $primaryColor;
        background: var(--card-image), var(--loader-url);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain, contain;

        overflow: hidden;

        transition: border-color $transition-duration;

        /* Text Container */
        .card-img-overlay {
            color: rgba(255, 255, 255, 1);
            background-color: rgba($primaryColor, 0.75);
            height: 100%;
            bottom: unset !important;
            top: 0;
            opacity: 0;

            transition: opacity calc(1.5*$transition-duration);
        }

        &:hover {
            border-color: rgba(var(--action-color), 1);

            .card-img-overlay {
                opacity: 1;
            }
        }
    }
}