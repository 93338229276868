@use "../../global" as *;

#news-page {
    .news-row {
        overflow: clip;
        @include flexDiv();

        .card {
            border: 1px solid $primaryColor;
            
            a {
                text-decoration: none !important;
            }

            .news-image {
                overflow: clip;
                width: 100% !important;
                height: 15rem !important;
                // border-radius: 0.4rem;

                span {
                    display: block !important;
                    height: 15rem !important;
                }

                img {
                    width: 100% !important;
                    height: 15rem !important;
                    object-fit: fill !important;
                    scale: 1;
                    transition: scale ease-in-out 0.4s;
                }
            }

            .card-body {
                .card-title {
                    color: $primaryColor;
                }
            }

            &:hover {
                .news-image {
                    img{
                        scale: 1.2;
                    }
                }
                .card-body {
                    .card-title {
                        color: $actionColor;
                    }
                }
            }
        }
    }
}