/* Footer Container */
#footer-component {
    background-color: rgba(var(--primary-color), 1);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover, cover, contain;
    background-attachment: fixed, fixed, local;

    color: #ffffff;
}

/* Links Container */
#footer-component .links-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#footer-component .important-link {
    display: inline-block;
    text-decoration: none;
    color: #ffffff;
    padding: 0.25rem;

    transition-property: color, padding-left;
    transition-duration: calc(2*var(--transition-duration));
}

#footer-component .important-link:not(:last-of-type) {
    margin-bottom: 0.5rem;
}

#footer-component .important-link:hover {
    color: rgba(var(--action-color), 1);
    padding-left: 0.8rem;
}

/* Contact Info */
#footer-component .contact-icon {
    min-width: 2rem !important;
    height: 1.6rem !important;
    margin-right: 0.5rem !important;
}

#footer-component .contact {
    text-decoration: none;
    color: #ffffff;

    transition: color var(--transition-duration);
}

#footer-component .contact:hover {
    color: rgba(var(--action-color), 1);
}

/* Footer Search */
#footer-component .form-control {
    background-color: #ffffff !important;
    cursor: pointer;
    caret-color: transparent;
}

#footer-component .form-control:focus {
    box-shadow: none !important;
}

#footer-component #footer-logo {
    margin: 0 0 0.5rem 0;
}

#footer-component #copyrights {
    font-size: 0.8rem;
    margin-block-start: 0.5rem;
}

/* Media Queries */
@media only screen and (max-width: 992px) {
    #footer-component #footer-logo {
        margin: 0 auto 0.8rem;
    }

    #footer-component .row>[class^='col'] {
        margin-top: 0.8rem;
    }

    #footer-component #copyrights {
        margin-top: 0.8rem !important;
    }
}

@media only screen and (max-width: 768px) {
    #footer-component #footer-logo {
        margin: 0 auto 0.8rem;
    }

    #footer-component .row>[class^='col'] {
        margin-top: 0.8rem;
    }

    #footer-component #copyrights {
        margin-top: 0.8rem !important;
    }
}
