  /* #143c72 */
  $primaryColor: rgb(20, 60, 114);
  /* #f2ab14 */
  $actionColor: rgb(242, 171, 20);

  /* #445058 */
  $disabledColor: rgb(68, 80, 88);

  $transition-duration: 250ms;

  @mixin flexDiv() {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

@mixin flexStartMiddleDiv() {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}