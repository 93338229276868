@use "../../global" as *;

#news-page{
    @include flexDiv();
    flex-direction: column;
    width: 100% !important;

    .single-news-row {
        overflow: clip;
    
        .single-news-image {
            overflow: clip;
            width: 100% !important;
            height: 100% !important;
            min-height: 25rem !important;
            border: 1px solid $primaryColor;
            border-radius: 0.4rem;
    
            span {
                display: block !important;
                height: 100% !important;
                min-height: 25rem !important;
            }
    
            img {
                width: 100% !important;
                height: 100% !important;
                min-height: 25rem !important;
                object-fit: fill !important;
            }
    
            &:hover {
                cursor: pointer;
            }
        }
    }
}
