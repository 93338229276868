@use './../../global' as *;

#portfolio-section {
    .portfolio-section-title {
        margin-block-end: 2rem;
    }

    .portfolio-slider {

        .swiper-button-prev,
        .swiper-button-next {
            color: $primaryColor !important;
        }

        .card {
            border-color: $primaryColor !important;
            transition: border-color $transition-duration;
            .portfolio-image {
                overflow: clip;
                width: 100% !important;
                height: 15rem !important;
                // border-radius: 0.4rem;

                span {
                    display: block !important;
                    height: 15rem !important;
                }

                img {
                    width: 100% !important;
                    height: 15rem !important;
                    object-fit: fill !important;
                    scale: 1;
                    transition: scale ease-in-out 0.4s;
                }
            }

            &:hover {
                border-color: $actionColor !important;
            }

            .portfolio-title {
                color: $primaryColor !important;
                font-weight: 500 !important;
            }

            .description{
                padding-inline: 0 !important;
            }
        }
    }
}