#loading-component {
    --background: 255, 255, 255;
    --front-color: var(--primary-color);
    --back-color: var(--disabled-color);

    background: rgba(var(--background), 1);
}

#wifi-loader {
    width: 64px;
    height: 64px;
    border-radius: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#wifi-loader svg {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

#wifi-loader svg circle {
    position: absolute;
    fill: none;
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transform: rotate(-100deg);
    transform-origin: center;
}

#wifi-loader svg circle.back {
    stroke: rgba(var(--back-color), 1);
}

#wifi-loader svg circle.front {
    stroke: rgba(var(--front-color), 1);
}

#wifi-loader svg.circle-outer {
    height: 86px;
    width: 86px;
}

#wifi-loader svg.circle-outer circle {
    stroke-dasharray: 62.75 188.25;
}

#wifi-loader svg.circle-outer circle.back {
    -webkit-animation: circle-outer 1.8s ease infinite 0.3s;
    animation: circle-outer 1.8s ease infinite 0.3s;
}

#wifi-loader svg.circle-outer circle.front {
    -webkit-animation: circle-outer 1.8s ease infinite 0.15s;
    animation: circle-outer 1.8s ease infinite 0.15s;
}

#wifi-loader svg.circle-middle {
    height: 60px;
    width: 60px;
}

#wifi-loader svg.circle-middle circle {
    stroke-dasharray: 42.5 127.5;
}

#wifi-loader svg.circle-middle circle.back {
    -webkit-animation: circle-middle 1.8s ease infinite 0.25s;
    animation: circle-middle 1.8s ease infinite 0.25s;
}

#wifi-loader svg.circle-middle circle.front {
    -webkit-animation: circle-middle 1.8s ease infinite 0.1s;
    animation: circle-middle 1.8s ease infinite 0.1s;
}

#wifi-loader svg.circle-inner {
    height: 34px;
    width: 34px;
}

#wifi-loader svg.circle-inner circle {
    stroke-dasharray: 22 66;
}

#wifi-loader svg.circle-inner circle.back {
    -webkit-animation: circle-inner 1.8s ease infinite 0.2s;
    animation: circle-inner 1.8s ease infinite 0.2s;
}

#wifi-loader svg.circle-inner circle.front {
    -webkit-animation: circle-inner 1.8s ease infinite 0.05s;
    animation: circle-inner 1.8s ease infinite 0.05s;
}

@keyframes circle-outer {
    0% {
        stroke-dashoffset: 25;
    }

    25% {
        stroke-dashoffset: 0;
    }

    65% {
        stroke-dashoffset: 301;
    }

    80% {
        stroke-dashoffset: 276;
    }

    100% {
        stroke-dashoffset: 276;
    }
}

@keyframes circle-middle {
    0% {
        stroke-dashoffset: 17;
    }

    25% {
        stroke-dashoffset: 0;
    }

    65% {
        stroke-dashoffset: 204;
    }

    80% {
        stroke-dashoffset: 187;
    }

    100% {
        stroke-dashoffset: 187;
    }
}

@keyframes circle-inner {
    0% {
        stroke-dashoffset: 9;
    }

    25% {
        stroke-dashoffset: 0;
    }

    65% {
        stroke-dashoffset: 106;
    }

    80% {
        stroke-dashoffset: 97;
    }

    100% {
        stroke-dashoffset: 97;
    }
}
