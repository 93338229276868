/* Service Container */
#home-page .service-card-component {
    height: 36rem;
    border-color: rgba(var(--primary-color), 1);

    transition: border-color var(--transition-duration);
}

#home-page .service-card-component:hover {
    border-color: rgba(var(--action-color), 1);
}

@media only screen and (max-width: 576px) {
    #home-page .service-card-component {
        text-align: center;
    }
}

/* Service Image */
#home-page .service-card-component .image-container {
    overflow: hidden;

}

#home-page .service-card-component img {
    height: 100%;
    transform: scale(1);

    transition: transform calc(2*var(--transition-duration));
}

#home-page .service-card-component:hover img {
    transform: scale(1.1);
}

/* Service Title */
#home-page .service-card-component .card-title {
    color: rgba(var(--primary-color), 1);

    transition: color var(--transition-duration);
}

#home-page .service-card-component:hover .card-title {
    color: rgba(var(--action-color), 1);
}

/* Service Description */
#home-page .service-card-component .card-text * {
    padding: 0 !important;
}