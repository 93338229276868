/* Table Data */
#catalog-details-page .table tr th,
#catalog-details-page .table tr td {
    white-space: nowrap;
    vertical-align: middle;
}

/* Table Head */
#catalog-details-page .table th {
    position: sticky;
    top: 0;
    z-index: 99;
}
