/* Root */
:root {
  /* #143c72 */
  --primary-color: 20, 60, 114;

  /* #f2ab14 */
  --action-color: 242, 171, 20;

  /* #445058 */
  --disabled-color: 68, 80, 88;

  --transition-duration: 250ms;
}

/* Reset Styles */
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Paragraphs Font Family */
*:not([class^="title"]) {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
}

/* Titles Font Family */
*[class^="title"] {
  font-family: 'DM Serif Display', serif;
  font-weight: 600;
}

#root {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

main {
  min-height: 100vh;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Text Limit */
.text-limit {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: var(--lines) !important;
  line-clamp: var(--lines) !important;
  -webkit-box-orient: vertical !important;
}

.custom-container {
  /* max-width: 60rem !important; */
}

.footer{
  padding-top: 2.5rem;
}

.custom-margin {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.custom-gutter {
  --bs-gutter-y: 3rem !important;
  --bs-gutter-x: 3rem !important;
}

@media only screen and (max-width: 1400px) {
  .custom-container {
    /* background-color: black; */
  }
}

@media only screen and (max-width: 1200px) {
  /* .custom-container {
    max-width: 52rem !important;
  } */
  .footer{
    max-width: 52rem !important;
  }
}

@media only screen and (max-width: 992px) {
  /* .custom-container {
    max-width: 40rem !important;
  } */
  .footer{
    max-width: 52rem !important;
  }
}

@media only screen and (max-width: 768px) {
  /* .custom-container {
    max-width: 34rem !important;
  } */
  .footer{
    max-width: 52rem !important;
    padding-top: 1.5rem;
  }
}

@media only screen and (max-width: 576px) {
  /* .custom-container {
    max-width: 25rem !important;
  } */
  .footer{
    max-width: 25rem !important;
    padding-top: 1.5rem;
  }
}
