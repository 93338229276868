.navbar {
  padding: 0 !important;
}

/* Navbar Content Container */
#navbar-content {
  position: relative;
  background-color: #ffffff;
  padding: 0 0.5rem !important;
}

@media only screen and (max-width: 1200px) {
  #navbar-content {
    padding: 0.5rem !important;
  }
}

/* Toggle Icon Container */
.navbar-toggler {
  color: #ffffff !important;
  border: none !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-toggler.collapsed {
  color: rgba(var(--primary-color), 1) !important;
  background-color: transparent !important;
}

/* Toggle Icon */
.navbar-toggler-icon {
  background-image: none !important;
  position: relative !important;
  background-color: rgba(var(--primary-color), 0.4);
  height: 0.1rem !important;

  transition: background-color 350ms;
}

.navbar-toggler.collapsed:hover .navbar-toggler-icon {
  background-color: rgba(var(--primary-color), 1);
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: "";
  background-color: inherit;
  height: inherit;
  position: absolute;
  right: 0;
  top: 0.5rem;
  width: 100%;
}

.navbar-toggler-icon::before {
  top: -0.5rem;
  width: 50%;

  transition: width 350ms;
}

.navbar-toggler.collapsed:hover .navbar-toggler-icon::before {
  width: 100%;
}

/* Navbar Links */
.offcanvas-body .nav-link {
  color: rgba(var(--primary-color), 0.65);
  text-transform: uppercase !important;
  font-size: 0.9rem !important;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.25rem 0.5rem;

  transition: color var(--transition-duration);
}

.offcanvas-body .nav-link {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  font-weight: normal;
  /* transition: font-weight ease-in-out 0.4s; */
}

.offcanvas-body .nav-link.active,
.offcanvas-body .nav-link:hover {
  color: rgba(var(--action-color), 1) !important;
  /* font-weight: bold; */
}

@media only screen and (max-width: 1200px) {
  .navbar-nav .nav-link:last-child {
    order: -1;
  }

  .navbar-nav .nav-link:nth-child(1) {
    order: -4;
  }

  .navbar-nav .nav-link:nth-child(2) {
    order: -3;
  }

  .navbar-nav .nav-link:nth-child(3) {
    order: -2;
  }
}

/* Dropdown Container */
.offcanvas-body .dropdown-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 1200px) {
  .offcanvas-body .dropdown-toggle {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.offcanvas-body .dropdown-toggle.show {
  color: rgba(var(--action-color), 1);
}

.offcanvas-body .dropdown-toggle::after {
  display: none;
}

.offcanvas-body .dropdown-toggle svg {
  margin-left: 2px;
}

/* Dropdown Menu */
.dropdown-menu {
  cursor: default;
  width: 100%;
  height: 20rem;
  border-radius: 0 !important;
  border: none;
  padding: 0.5rem 1.5rem;
  transform: translate(0, -150%);
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  z-index: -1;
}

.info-nav-dropdown .dropdown-menu {
  height: fit-content !important;
  padding: unset;
}

.info-nav-dropdown .dropdown-menu .dropdown-item {
  width: 100% !important;
}

@media only screen and (max-width: 1200px) {
  .dropdown-menu {
    height: unset;
  }
}

.dropdown.is-closed .dropdown-menu,
.dropdown.is-open .dropdown-menu {
  animation: calc(2 * var(--transition-duration)) both normal ease-in-out;
}

.dropdown.is-closed .dropdown-menu {
  animation-name: slideUp;
}

.dropdown.is-open .dropdown-menu {
  animation-name: slideDown;
}

@media only screen and (max-width: 1200px) {
  .dropdown.is-closed .dropdown-menu {
    animation-name: slideLeft;
  }

  .dropdown.is-open .dropdown-menu {
    animation-name: slideRight;
  }
}

@keyframes slideDown {
  from {
    transform: translate(0, -200%);
  }

  to {
    transform: translate(0, -0.6rem);
  }
}

@keyframes slideUp {
  from {
    transform: translate(0, -0.6rem);
  }

  to {
    transform: translate(0, -200%);
  }
}

@keyframes slideLeft {
  from {
    transform: translate(0, -0.5rem);
  }

  to {
    transform: translate(-150%, 0);
  }
}

@keyframes slideRight {
  from {
    transform: translate(-150%, 0);
  }

  to {
    transform: translate(0, -0.5rem);
  }
}

.dropdown-item {
  cursor: default;
  width: 25%;
  height: 100%;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.dropdown-item p {
  white-space: normal !important;
}

@media only screen and (max-width: 1200px) {
  .dropdown-item {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-item p {
    text-align: center;
  }
}

.dropdown-item:hover,
.dropdown-item:active {
  background-color: #f1f2f3;
  color: rgba(var(--primary-color), 1);
}

.dropdown-item .btn-base {
  color: rgba(var(--primary-color), 1);
  background: rgba(var(--action-color), 0.75);
  pointer-events: all;
}

.dropdown-item .btn:hover {
  cursor: pointer;
  color: rgba(var(--primary-color), 1);
  background-color: rgba(var(--action-color), 1);
}

/* Search Icon */
.navbar #search-icon {
  color: rgba(var(--primary-color), 0.65);
  margin: 0 0.5rem;
  order: 1;

  transition: color var(--transition-duration);
}

.navbar #search-icon:hover {
  color: rgba(var(--primary-color), 1);
}

/* Media Queries */
@media only screen and (max-width: 1200px) {

  /* Search Icon */
  .navbar #search-icon {
    order: 0;
  }
}

@media only screen and (max-width: 992px) {
  .offcanvas-body .nav-link {
    margin-right: 0;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
  }

  /* .dropdown-item {
        width: 50%;
    } */
}

/* @media only screen and (max-width: 576px) {
    .dropdown-item {
        width: 100%;
    }
} */