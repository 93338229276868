@use "../../global" as *;

.warrant-row {
    overflow: clip;

    .warranty-image {
        overflow: clip;
        width: 100% !important;
        height: 100% !important;
        min-height: 25rem !important;
        border: 1px solid $primaryColor;
        border-radius: 0.4rem;

        span {
            display: block !important;
            height: 100% !important;
            min-height: 25rem !important;
        }

        img {
            width: 100% !important;
            height: 100% !important;
            min-height: 25rem !important;
            object-fit: fill !important;
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.file-col{
    @include flexStartMiddleDiv();
    .file-text{
        margin-bottom: 0 !important;
        margin-inline-end: 0.5rem;
    }
}