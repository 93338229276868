#no-result-component {
    background-color: transparent;
    color: rgba(var(--primary-color), 1)
}

#no-result-component .btn {
    color: rgba(var(--primary-color), 1);
    background: rgba(var(--action-color), 0.85);

    transition-property: top, box-shadow;
    transition-duration: calc(2*var(--transition-duration)), var(--transition-duration);
}

#no-result-component .btn:hover {
    cursor: pointer;
    color: rgba(var(--primary-color), 1);
    background-color: rgba(var(--action-color), 1);

    top: -0.15rem;
    box-shadow: 0 0.15rem 0.15rem rgba(var(--primary-color), 0.25);
}
